<template>
  <section>
    <el-card>
      <el-progress
        :stroke-width="15"
        :color="getCompanyDetails.dialog_header_color"
        :percentage="percentage"
        :show-text="false"
      ></el-progress>
      <div v-if="showAnimation">
        <div>
        <i class="el-icon-loading"></i>
        <p>We are currently generating your application. This process will take approximately 1-2 minutes. Please wait.</p>
      </div>
        <Animation />
      </div>
      <div v-else>
         <div>
        <i class="el-icon-loading"></i>
        <p><b>Exciting update! </b>We're almost there. Your application is almost complete. Just a moment more...!</p>
      </div>
      <Animation2 />
      </div>
      <div class="mt-1">
        <ul>
          <li v-for="feature in visibleFeatures" :key="feature">
            <span>•</span> {{ feature }}
          </li>
        </ul>
      </div>
    </el-card>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "loadingComponent",
  props: ["features", "successMessage"],
  components: {
    Animation: () => import("./GeneratingAnimation.vue"),
    Animation2: () => import("./Animation2.vue"),
  },
  data() {
    return {
      percentage: 0,
      timer: null,
      showAnimation: true,
      featureTimer: null,
      visibleFeatures: [],
      defaultFeatures: [
        "Enable rapid prototyping and development, allowing businesses to quickly create and deploy applications without the need for extensive coding.",
        "Harness the speed of generative AI, ensuring your custom applications are always ahead of schedule.",
        "Transform ideas into reality quickly with AI capabilities, ensuring swift custom application delivery.",
        "Design any custom software solution without writing a single line of code.",
        "Reduce the need for professional developers with our no-code platform and significantly lower development costs, making it more affordable for small and medium-sized businesses.",
        "Kodefast allows non-developers, such as business analysts and project managers, to build and customize applications, democratizing the development process.",
        "Kodefast enables quick adjustments and iterations, allowing businesses to respond swiftly to changing market conditions and customer needs.",
      ],
      currentFeatures: this.features,
    };
  },
  computed: {
    ...mapGetters("company", ["getCompanyDetails"]),
  },
  beforeDestroy() {
    // this.$message.success(this.successMessage);
    clearInterval(this.timer);
    clearInterval(this.featureTimer);
    this.percentage = 100;
    setTimeout(() => {
      this.percentage = 0;
    }, 1000);
  },
  mounted() {
    if (!this.currentFeatures?.length) {
      this.currentFeatures = this.defaultFeatures;
    }
    this.startProgress();
    this.showFeatures();
    this.toggleAnimation();
  },
  methods: {
    startProgress() {
      this.timer = setInterval(() => {
        if (this.percentage < 96) {
          this.percentage += 1;
        } else {
          clearInterval(this.timer);
        }
      }, 800);
    },
    showFeatures() {
      let index = 0;
      const loopFeatures = () => {
        if (index >= this.currentFeatures.length) {
          index = 0;
        }
        this.visibleFeatures = [this.currentFeatures[index]];
        index++;
        this.featureTimer = setTimeout(loopFeatures, 4000);
      };
      loopFeatures();
    },
    toggleAnimation() {
      setTimeout(() => {
        this.showAnimation = false;
      }, 35000);
  }}
};
</script>

<style scoped>
.el-divider--horizontal {
  margin: 12px 0px 16px 0px;
}
</style>
